import "../App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../components/layouts/Home";
import Productdetails from "../components/layouts/Productdetails";
import Purchase from "../components/layouts/Purchase";
import Shopcheckout from "../components/layouts/Shopcheckout";
// import "../src/assets/css/style.css";
import "../../src/assets/css/style.css";
import Shopcart from "../components/layouts/Shopcart";
import Pageregister from "../components/Pageregister";
import About from "../components/layouts/About";
import Contact from "../components/layouts/Contact";
import Myaccount from "../components/layouts/Myaccount";
import Shopwishlist from "../components/layouts/Shopwishlist";
import Login from "../components/Login";
import Profile from "../components/layouts/Profile";

const authProtectedRoutes = [{}];

const publicRoutes = [
  { path: "/", component: Home },
  { path: "/productdetails", component: Productdetails },
  { path: "/purchase", component: Purchase },
  { path: "/purchase/:id", component: Purchase },
  { path: "/shopcheckout", component: Shopcheckout },
  { path: "/shopcart", component: Shopcart },
  { path: "/pageregister", component: Pageregister },
  { path: "/login", component: Login },
  { path: "/about", component: About },
  { path: "/contact", component: Contact },
  { path: "/orders", component: Myaccount },
  { path: "/shopwishlist", component: Shopwishlist },
  { path: "/profile", component: Profile },
];

export { authProtectedRoutes, publicRoutes };
