import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

import logo from "../../src/assets/imgs/sikan-logo.png";
import en from "../../src/assets/imgs/template/en.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import flagen from "../../src/assets/imgs/template/flag-en.svg";
import flages from "../../src/assets/imgs/template/flag-es.svg";
import flagpt from "../../src/assets/imgs/template/flag-pt.svg";
import flagcn from "../../src/assets/imgs/template/flag-cn.svg";
import flagfr from "../../src/assets/imgs/template/flag-fr.svg";
import { useDispatch, useSelector } from "react-redux";
import { cartGetRequest } from "../store/cart/cart.action";
import { cartSelector } from "../store/cart/cart.model";
import { productSelector } from "../store/products/products.model";
import { createProductPayload } from "../utils/utils";
import { filterProductGetRequest } from "../store/products/products.action";
import { getUserDataRequest } from "../store/user/user.action";

function Header() {
  const isAuth = localStorage.getItem("isAuthenticated");
  const role = localStorage.getItem("role");
  const id = localStorage.getItem("id");

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { getCarts } = useSelector(cartSelector);
  const { filterProductData } = useSelector(productSelector);
  console.log(filterProductData, "67867dfg");
  /* State */
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  /* Function */
  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion.name);
    setSuggestions([]);
    navigate(`/purchase?query=${encodeURIComponent(suggestion.name)}`);
  };
  console.log(suggestions, "6789");
  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchQuery(input);
    console.log(input, "456uisf");
    if (input.length > 3) {
      const payload = createProductPayload("", "", input, "");
      dispatch(filterProductGetRequest(payload));

      setSuggestions(filterProductData);
    } else {
      setSuggestions([]);
    }
  };
  const handleClick = () => {
    const payload = {
      userId: id,
    };
    dispatch(getUserDataRequest(payload));
  };
  /* useEffect */
  useEffect(() => {
    const payload = {
      page: 0,
      limit: 0,
      query: "",
    };
    dispatch(cartGetRequest(payload));
  }, []);

  return (
    <>
      <header className="header sticky-bar">
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <Link to="/" className="active">
                  <a className="d-flex">
                    <img alt="Ecom" src={logo} />
                  </a>
                </Link>
              </div>
              <div className="header-search">
                <div className="box-header-search ml-30">
                  <form className="form-search">
                    <div className="box-keysearch">
                      <input
                        className="form-control font-xs"
                        type="text"
                        value={searchQuery}
                        placeholder="Search for items"
                        onChange={handleSearchInputChange}
                      />
                    </div>
                  </form>
                  <div className="suggestions">
                    {suggestions?.length > 0 && (
                      <ul className="suggestion-list">
                        {suggestions?.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              <>
                <div className="header-nav">
                  <nav className="nav-main-menu  d-xl-block">
                    <ul className="main-menu">
                      <li>
                        <Link to="/" className="active">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/purchase">Purchase</Link>
                      </li>
                      {isAuth ? (
                        <li>
                          <a>My account</a>
                          <ul className="top-50">
                            <Link to="/profile" onClick={handleClick}>
                              <li className="active">Profile</li>
                            </Link>
                            <Link to="/orders">
                              <li className="active">Orders</li>
                            </Link>
                          </ul>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </nav>
                  <div className="burger-icon burger-icon-white">
                    <span className="burger-icon-top"></span>
                    <span className="burger-icon-mid"></span>
                    <span className="burger-icon-bottom"></span>
                  </div>
                </div>
              </>

              <div className="header-shop">
                <div className="d-inline-block box-dropdown-cart">
                  {isAuth ? (
                    <Link to="/shopcart" className="active">
                      <span className="font-lg icon-list icon-cart">
                        <span>Cart</span>
                        <span className="number-item font-xs">
                          {getCarts?.length}
                        </span>
                      </span>
                    </Link>
                  ) : (
                    <Link to="/login" className="active">
                      <span>Login</span>
                    </Link>
                  )}
                  <div className="dropdown-cart">
                    <div className="item-cart mb-20">
                      <div className="cart-image">
                        <img
                          src="assets/imgs/page/homepage1/imgsp5.png"
                          alt="Ecom"
                        />
                      </div>
                      <div className="cart-info">
                        <a
                          className="font-sm-bold color-brand-3"
                          href="shop-single-product.html"
                        >
                          2022 Apple iMac with Retina 5K Display 8GB RAM, 256GB
                          SSD
                        </a>
                        <p>
                          <span className="color-brand-2 font-sm-bold">
                            1 x $2856.4
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="item-cart mb-20">
                      <div className="cart-image">
                        <img
                          src="assets/imgs/page/homepage1/imgsp4.png"
                          alt="Ecom"
                        />
                      </div>
                      <div className="cart-info">
                        <a
                          className="font-sm-bold color-brand-3"
                          href="shop-single-product-2.html"
                        >
                          2022 Apple iMac with Retina 5K Display 8GB RAM, 256GB
                          SSD
                        </a>
                        <p>
                          <span className="color-brand-2 font-sm-bold">
                            1 x $2856.4
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="border-bottom pt-0 mb-15"></div>
                    <div className="cart-total">
                      <div className="row">
                        <div className="col-6 text-start">
                          <span className="font-md-bold color-brand-3">
                            Total
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="font-md-bold color-brand-1">
                            $2586.3
                          </span>
                        </div>
                      </div>
                      <div className="row mt-15">
                        <div className="col-6 text-start">
                          <a
                            className="btn btn-cart w-auto"
                            href="shop-cart.html"
                          >
                            View cart
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            className="btn btn-buy w-auto"
                            href="shop-checkout.html"
                          >
                            Checkout
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
