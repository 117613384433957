import { secretKey } from "../../services/config";
import { cartActionTypes, cartInitialState } from "./cart.model";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const reducer = (state = cartInitialState, action) => {
  switch (action.type) {
    case cartActionTypes.CARTS_GET_REQUEST:
      return {
        ...state,
        cartloading: true,
      };
    case cartActionTypes.CARTS_GET_SUCCESS:
      return {
        ...state,
        cartloading: false,
        getCarts: action?.payload?.data?.datas?.data,
      };
    case cartActionTypes.CARTS_GET_FAILURE:
      return {
        ...state,
        cartloading: false,
      };
    case cartActionTypes.ADDTOCART_POST_REQUEST:
      return {
        addCardLoad: true,
      };
    case cartActionTypes.ADDTOCART_POST_SUCCESS:
      return {
        addCardLoad: false,
        succesMessage: action?.payload?.data?.message,
      };
    case cartActionTypes.ADDTOCART_POST_FAILURE:
      return {
        addCardLoad: false,
      };

    case cartActionTypes.CHECKOUT_POST_REQUEST:
      return {
        checkOutLoad: true,
      };
    case cartActionTypes.CHECKOUT_POST_SUCCESS:
      return {
        checkOutLoad: false,
        succesMessage: action?.payload?.data?.message,
      };
    case cartActionTypes.CHECKOUT_POST_FAILURE:
      return {
        checkOutLoad: false,
      };
      case cartActionTypes.ORDERDETAIL_GET_REQUEST:
      return {
        checkOutLoad: true,
      };
    case cartActionTypes.ORDERDETAIL_GET_SUCCESS:
        let payloadData = JSON.parse(
            EncryptDecrypt.decryptdata(action?.payload.data?.datas, secretKey)
          );
      return {
        ordergetLoad: false,
        orderDetailData: payloadData?.data,
      };
    case cartActionTypes.ORDERDETAIL_GET_FAILURE:
        
      return {
        ordergetLoad: false,
      };
    case cartActionTypes.STATE_RESET_REQUEST:
      return {
        ...state,
        ordergetLoad: false,
        succesMessage: "",
      };

    default:
      return state;
  }
};

export { reducer as cartReducer };
