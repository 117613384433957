import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { AuthReducer } from "./auth/auth.reducer";

import { AuthActionTypes } from "./auth/auth.model";
import { productReducer } from "./products/products.reducer";
import { cartReducer } from "./cart/cart.reducer";
import { CategoryAddReducer } from "./category/category.reducer";
import { UserReducer } from "./user/user.reducer";

const appReducer = combineReducers({
  AuthReducer,
  productReducer,
  cartReducer,
  CategoryAddReducer,
  UserReducer
});

const persistConfig = {
  key: "socialapp",
  storage: storageSession,
};
const rootReducer = (state, action) => {
  if (action.type === AuthActionTypes.CLEAR_USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
