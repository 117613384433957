import { call, all, put, takeEvery } from "redux-saga/effects";

import * as cartActions from "./cart.action";
import { cartActionTypes } from "./cart.model";
import { addCardApi, checkOutApi, getCartApi, getOrderDetialApi } from "./cart.api";

export function* handleGetCarts(action) {
  console.log(action.payload, "6789");
  try {
    const response = yield call(getCartApi, action.payload);
    yield put(cartActions.cartGetSuccess(response));
  } catch (e) {
    yield put(cartActions.cartGetFailure(e));
  }
}

export function* handlePostToCard(action) {
  try {
    const response = yield call(addCardApi, action.payload);
    yield put(cartActions.postAddToCardsuccess(response));
  } catch (err) {
    yield put(cartActions.postAddToCardFailure(err));
  }
}
export function* handleCheckout(action) {
  try {
    const response = yield call(checkOutApi, action.payload);
    yield put(cartActions.checkOutDataSuccess(response));
  } catch (err) {
    yield put(cartActions.checkOutDataFailure(err));
  }
}
export function* handlegetOrders(action) {
    try {
      const response = yield call(getOrderDetialApi, action.payload);
      yield put(cartActions.getOrderDetailSuccess(response));
    } catch (err) {
      yield put(cartActions.getOrderDetailFailure(err));
    }
  }
export function* cartSaga() {
  yield takeEvery(cartActionTypes.CARTS_GET_REQUEST, handleGetCarts);
  yield takeEvery(cartActionTypes.ADDTOCART_POST_REQUEST, handlePostToCard);
  yield takeEvery(cartActionTypes.CHECKOUT_POST_REQUEST, handleCheckout);
  yield takeEvery(cartActionTypes.ORDERDETAIL_GET_REQUEST, handlegetOrders);
}
